<template>

    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
<!--        <left-bar-comp>-->
<!--            <sports-left-bar-comp></sports-left-bar-comp>-->
<!--        </left-bar-comp>-->

        <div class="content_section">
            <div class="content">
                <sub-title>고객센터</sub-title>
              <div class="main_panel">
                <div class="qa_item">
                  <div class="qa">
                    <div class="qt">
                      문의내용
                    </div>
                    <textarea v-model="content" class="texta"></textarea>
                    <div class="btn-area">
                      <button class="btn-write" @click="saveQuestion()">문의하기
                      </button>
                      <button class="btn-del-all" @click="delAll()">전체삭제
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="main_panel">
                    <div class="qa_item" v-for="(item, index) in questionList" :key="index">
                        <router-link tag="div"  :to="{path:'/customer_center_detail/' + item.id}" class="qa_title" >
                            <span style="color: #a4a6a7"></span> {{item.content}}
                        </router-link>
                        <div class="qa_status">
                            <span v-if="item.contentReply">완료</span>
                            <span style="color: #d0cfd0" v-if="!item.contentReply">대기</span>
                        </div>
                        <div class="qa_time">
                            {{item.createTime|datef('MM/DD HH:mm')}}
                        </div>
                    </div>
                </div>

                <!--페이지-->
                <pagination :page-index="pageNum"
                            :total="total"
                            :page-size="pageSize"
                            @change="pageChange"
                            v-if="questionList.length > 0"></pagination>
                <div class="main_panel">
                    <sports-bet-list-comp
                            :bet-list="betList"
                            :position="0"></sports-bet-list-comp>
                </div>


            </div>
        </div>

        <!-- right 메뉴 -->
        <!--<right-bar-comp>
               <user-info-comp slot="userinfo"></user-info-comp>
               <right-buttons-comp slot="btns"></right-buttons-comp>
               <sports-bet-cart-comp slot="betcart"></sports-bet-cart-comp>
               <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
           </right-bar-comp>-->
        <foot-comp></foot-comp>

    </div>


</template>

<script>
    import RightBarComp from "../../components/RightBarComp";
    import SubTitle from "../../components/SubTitle";
    import {delAllQuestion, delQuestion, getQuestionList, saveQuestion} from "../../network/userRequest";
    import Pagination from "../../components/pagenation/Pagination";
    import LeftBarComp from "../../components/LeftBarComp";
    import LeisureLeftComp from "../../components/leisuregame/LeisureLeftComp";
    import FootComp from "../../components/FootComp";
    import TopbarComp from "../../components/TopbarComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import sportsConst from "../../common/sportsConst";
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../../store/mutation-types";
    import {postionMixin} from "../../common/mixin";
    import UserInfoComp from "../../components/UserInfoComp";
    import RightButtonsComp from "../../components/RightButtonsComp";
    import SportsBetCartComp from "../../components/sports/SportsBetCartComp";
    import {getBetById} from "../../network/sportsBetRequest";
    import SportsBetListComp from "../../components/sports/SportsBetListComp";

    export default {
        name: "CustomerCenter",
        mixins: [postionMixin],
        components: {
            SportsBetListComp,
            SportsBetCartComp,
            RightButtonsComp,
            UserInfoComp,
            SportsLeftBarComp,
            RightBarBannerComp,
            TopbarComp, FootComp, LeisureLeftComp, LeftBarComp, Pagination, SubTitle, RightBarComp
        },
        data() {
            return {
                pageNum: 1,
                pageSize: 5,
                total: 1,
                orderBy: null,
                search: {},
                questionList: [],
                content: null,
                sportsConst,
                currentId: 0,
                position: "고객센터",
                type: sportsConst.CUSTOMER_TYPE_NORMAL,
                editorOption: {
                    modules: {
                        toolbar: [
                            ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
                            [{font: []}], // 글자체
                            /*["blockquote", "code-block"], // 引用  代码块
                            [{ header: 1 }, { header: 2 }], // 1、2 级标题
                            [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
                            [{ script: "sub" }, { script: "super" }], // 上标/下标
                            [{ indent: "-1" }, { indent: "+1" }], // 缩进
                            // [{'direction': 'rtl'}],                         // 文本方向
                            [{ size: ["small", false, "large", "huge"] }], // 字体大小
                            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
                            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
                            [{ align: [] }], //
                            ["clean"], // 문구격식삭제
                            ["link", "image", "video"] // 링크、이미지、동영상*/
                        ], //기능바 세팅
                    },
                    placeholder: '', //提示
                    readyOnly: false, //是否只读
                    theme: 'bubble', //skin snow/bubble
                    syntax: false, //문법체크

                },
                betList: [],
                betid: '',
            }
        },

        methods: {
            del(id){
                delQuestion(id).then(res=>{
                    if (res.data.success) {
                        this.initQuestion()
                    }else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                })
            },
            showReplay(id) {
                if (this.currentId === id) {
                    this.currentId = 0
                } else {
                    this.currentId = id
                    this.initQuestion()
                }
            },
            delAll(){
                this.$swal({
                    title: '문의내역 전부 삭제',
                    type: 'question',
                    confirmButtonText: ' 예 ',
                    cancelButtonText: '아니오'
                }).then((res) => {
                    delAllQuestion().then(res=>{
                        if (res.data.success) {
                            this.content = ''
                            this.initQuestion()
                        } else {
                            this.$swal({
                                title: res.data.msg,
                                type: 'warning',
                                showCancelButton: false,
                                showConfirmButton: true
                            })
                        }
                    })
                });

            },
            saveQuestion() {
                // let regx = /^(?=.*[가-힣ㄱ-ㅎ].*).{1,}$/
                // if (!(regx.test(this.content))) {
                //   //if(false){
                //   this.$swal({
                //     title: '한글이 포함된 내용으로 작성해주세요',
                //     type: 'error',
                //     showCancelButton: false,
                //     showConfirmButton: true
                //   })
                //   return false;
                // }
                this.$store.commit(RECEIVE_SHOW_LOADING)
                saveQuestion({'content': this.content, 'type': this.type, 'betid': this.betid}).then(res => {
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                    this.type = sportsConst.CUSTOMER_TYPE_NORMAL
                    if (res.data.success) {
                        this.content = ''
                        this.$swal({
                            title: '문의완료',
                            type: 'success',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                        this.initQuestion()
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                })
            },
            questioAcNo() {
                this.content = '<p>계좌번호 문의합니다</p>'
                this.type = sportsConst.CUSTOMER_TYPE_BANK_CARD
                this.saveQuestion()
            },
            initQuestion() {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                getQuestionList(this.pageNum, this.pageSize, this.orderBy).then(res => {
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                    if (res.data.success) {
                        this.total = res.data.total
                        this.questionList = res.data.data
                    }
                })
            },
            //pagination.vue 에서 페이지 클릭 시 emit발송접수 메소드
            pageChange(page) {
                this.pageNum = page
                this.initQuestion()
            },

            onEditorBlur(editor) {
            },
            onEditorFocus(editor) {
            },
            onEditorReady(editor) {
            },
            onEditorChange(editor) {
                this.content = editor.html;
            },
        },
        computed: {
            editor() {
                return this.$refs.contentEditor.quillEditor
            }
        },
        mounted() {
        },
        created() {
            this.initQuestion()
            if (this.$route.query.betid) {
                this.betid = this.$route.query.betid
                getBetById(this.betid).then(res => {
                    if (res.data.success) {
                        this.betList = res.data.data;
                    }
                })
            }
        }
    }
</script>

<style scoped>
    @import url("../../assets/css/base.css");
    @import url("../../assets/css/common.css");
    @import url("../../assets/css/style.css");
    @import url("../../assets/css/media.css");
    @import url("../../assets/css/sports.css");
    .qa_item {
        width: 100%;
        background-color: var(--noticeBg);
        box-sizing: border-box;
        padding: 16px;
        border: 1px solid #525252;
        color: floralwhite;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .qa_item .qa_title{
        width: 60%;
        text-align: left;
        cursor: pointer;
    }
    .qa_status{
        width: 10%;
        text-align: center;
    }
    .qa_item .qa_time{
        width: 20%;
        text-align: right;
    }

    .qa_item .qa {
        width: 100%;
        min-height: 100px;
    }

    .qa_item .qt {
        padding: 16px 0;
        color: #fff9ff;
        font-weight: 600;
    }

    .qa_item .q {
        background-color: var(--noticeBg2);
        padding: 16px;
        color: #fff9ff;
        cursor: pointer;
    }
    .texta{
        width: 99% !important;
        height: 80px!important;
    }

    .qa_item .quill-editor {
        border: 1px solid #f19f09 !important;
        border-radius: 4px !important;
    }

    .qa_item .btn-area {
        width: 100%;
        text-align: center;
        box-sizing: border-box;
        padding-top: 10px;
    }

    .qa_item .btn-area .btn-write {
        width: 180px;
        display: inline-block;
        height: 32px;
        margin: 10px 10px;
        background-color: var(--loginBtnBg);
        font-weight: 500;
        font-size: 14px;
        color: #ffffff;
    }
    .qa_item .btn-area .btn-del-all {
        width: 180px;
        display: inline-block;
        height: 32px;
        margin: 10px 10px;
        background-color: #ffa604;
        font-weight: 500;
        font-size: 14px;
        color: #ffffff;
    }

    .qa_item .quill-editor {
        border: 1px solid #e0e0e0 !important;
        border-radius: 1px !important;
        background-color: #ffffff !important;
        color: #0c0e0e;
    }


</style>